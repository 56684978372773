import { extendTheme } from '@chakra-ui/react';
import { HEADING_FONT, BODY_FONT, HEADING_ALTERNATIVE, BODY_ALTERNATIVE } from '@/constants/fonts';
import {
  BG_PRIMARY_GRADIENT,
  BG_PRIMARY,
  BG_SECONDARY,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  TEXT_ALTERNATIVE,
  BG_SECONDARY_GRADIENT,
} from '@/constants/colors';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://cha
 kra-ui.com/guides/using-fonts
 */
const theme = {
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    heading: HEADING_FONT,
    cursive: HEADING_FONT,
    headingAlternative: HEADING_ALTERNATIVE,
    body: BODY_FONT,
    bodyAlternative: BODY_ALTERNATIVE,
    serif: BODY_FONT,
    jost: `'Jost', sans-serif;`,
    delafield: `'Mrs Saint Delafield', cursive;`,
  },
  colors: {
    bgPrimary: BG_PRIMARY,
    bgPrimaryGradient: BG_PRIMARY_GRADIENT,
    bgSecondaryGradient: BG_SECONDARY_GRADIENT,
    bgSecondary: BG_SECONDARY,
    mainColorText: TEXT_PRIMARY,
    secondaryColorText: TEXT_SECONDARY,
    alternativeColorText: TEXT_ALTERNATIVE,
    transparent: 'transparent',
    theme: {
      bgPrimary: BG_PRIMARY,
      bgSecondary: BG_SECONDARY,
    },
  },
  // override components
  components: {
    Heading: {
      baseStyle: {
        letterSpacing: 'normal',
        fontStyle:'italic'
      },
    },
    Text: {
      baseStyle: {
        letterSpacing: 'normal',
        fontSize:'16px'
      },
    },
    Button: {
      baseStyle: {
        fontFamily: 'bodyAlternative',
      },
    },
  },
};

export default extendTheme(theme);
