export const BG_PRIMARY = '#DBD0C0';
export const BG_PRIMARY_GRADIENT = 'rgb(152, 136, 118, 0.8)';
export const BG_SECONDARY_GRADIENT = 'rgb(152, 136, 118, 0.5)';
export const BG_SECONDARY = '#F6F0E7';

// color text
export const TEXT_PRIMARY = '#fff';
export const TEXT_SECONDARY = '#5B341E';
export const TEXT_ALTERNATIVE = '#aa7b29';

export const TEXT_SHADOW = '#3c1410';

// music & navigation
export const NAVIGATION_COLOR = '#ac955a';
export const ICON_NAVIGATION_COLOR = '#fff';

// DEFAULT BUTTON PROPS
export const BUTTON_GRADIENT = `linear-gradient(to right, var(--chakra-colors-bgPrimaryGradient), var(--chakra-colors-bgSecondary) 200%)`;
export const BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};

export const DEFAULT_BUTTON_PROPS = {};
